/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

// markup
const KKKPage = () => (
  <Layout pageTitle="Korduma kippuvad küsimused">
    <section className="container mt-6">
      <h2 className="subtitle ahjud-font-syncopate">Korduma kippuvad küsimused</h2>
      <div className="content">
        <h2 className="title is-4">Millal vana ahju parandada ja millal maha lõhkuda?</h2>
        <p>
          Kõigepealt tee selgeks, millal ahi ehitatud, ja siis lase asjatundjal – korstnapühkijal või pottsepal – üle vaadata, mis seisus see on.
          Alusta korstnapühkijast, sest ahi on võib-olla kaua kasutamata seisnud või on lihtsalt puhastamata ehk nõge täis.
          <br />
          Mõnikord aitab puhastamisel lisatud lapist või luugist, sest lõõrid on ahjude kõige olulisem osa nende hingeelust.
          <br />
          XX sajandi algul mõisatesse ehitatud ahjud on ühtlasi õhkküttega küttekehad, tavaliselt laotud kahlitest või pottidest. See tähendab, et kui pole suuremaid purustusi, on nad parimad suure ruumi soojendajad. Äärmisel juhul peab mõned üksikud osad välja vahetama.
          <br />
          Ka sõjaeelsed pottahjud on vastupidavad. Neil võivad olla keerulised ja raskestipuhastatavad lõõrid ja siin aitab mõne lisajuurdepääsu loomine regulaarseks puhastamiseks.
          <br />
          Liiga vanad ahjupotid või -kivid ei akumuleeri enam sooja. Nüüd peaks asjatundja ütlema, mida täpselt välja vahetada. Võib-olla tuleks ainult ahju ühele küljele muretseda uued potid ja vahetada üksnes sisu. Ikkagi oleks see odavam kui täiesti uue ehitamine. Eriti peaks see mõte sobima neile, kes soovivad säilitada ahju vana välisilmet.
          <br />
          Paaril sõjajärgsel aastakümnel laotud lainelised või siledad ümarad plekkahjud ei vääri paraku enamasti renoveerimise vaeva. Tõenäoliselt oli tollaste kivide kvaliteet kehv. Kui nad on tühjaks kukkunud ja kivid läbi põlenud, on õigem laduda uus ahi, sest ka plekist kesta ei saa enam kasutada.
        </p>
        <h2 className="title is-4">Kuidas ahi niimoodi soojaks kütta, et see ohutu oleks?</h2>
        <p>
          Vastavalt tuleohutuse seadusele peab kortermajade ja ridaelamute küttesüsteeme korra aastas puhastama kutseline korstnapühkija, kes väljastab tehtud tööde kohta akti. Eramajades võib korstent pühkida ka ise, kuid korra viie aasta jooksul peab süsteemid siiski üle vaatama kutseline korstnapühkija. Kütteseadmeid tasub aeg-ajalt näidata ka pottsepale. Tema oskab vajadusel parandustöid teha ja ahjude, kaminate üldise ohutuse osas nõu anda. Ise nokitsedes või ümberehitusi tehes ei pruugi see enam ohutu olla. Katkised, hooldamata või valesti ehitatud küttekolded põhjustavad samuti tulekahjusid.
          <br />
          Kindlasti tasub kontrollida, kas korstnapühkijal ja pottsepal on olemas kutsetunnistus, sest see tagab tema teadmised ja õigete töövõtete tundmise. Sellisel juhul on ka maja- või korteriomanikul kindlus küttesüsteemi tuleohutuse kohta.
          <br />
          <br />
          Aktiivsel kütmisel ladestub lõõridesse tahm, mistõttu võib ahi hakata sisse ajama või halvemal juhul võib tahm ka korstnas süttida. Suuremas koguses tahma ja nõge tekitavad vead kütmisel. Esiteks ei tohi kütmisel kasutada märga puitu. See põleb halvasti ja küttekoldes ei teki märja puidu maksimaalseks põlemiseks vajalikku temperatuuri. Suur osa energiast kulub lihtsalt puidus oleva vee aurustamiseks ning nii tekib palju põlemisjääke.
          <br />
          Teiseks tuleb kütteseadmete siibreid ja tõmbeuksi õigesti kasutada. Kütteseadme siiber tuleb kogu kütmise aja hoida täiesti avatud asendis, et õhu pealevool oleks põlemiseks piisav. Uuematel seadmetel tuleks jälgida kasutusjuhendit. Klassikaliste ahjude puhul, kus kolde all puudub tuharuum, peab õhuavadega koldeuks olema suletud, kuid välimine uks piisavalt avatud.
          <br />
          Restkoldega ahjude puhul peab tuharuumi uks olema avatud. Kuigi õhk on põlemiseks oluline, ei soovitata kütta ka täiesti avatud koldeustega. Sellisel juhul ei kasutata kogu lisandunud hapnikku põlemisel ära, vaid üleliigne õhk jahutab küttesüsteemi. Esimesel kütmisel ei ole mõistlik ahju panna tervet sületäit puid, vaid alustama peaks tagasihoidlikumalt. Ka hiljem ei tohi kütta järjest mitut ahjutäit.
          <br />
          <br />
          Väga külmal ajal on soovitatav ahju kütta kasvõi mitu korda päevas, aga mitte liiga palju korraga. Arvestada tuleb ka puude kütteväärtusega. Tavaline lepp annab vähem sooja kui näiteks kase- või saarepuu. Ülekütmisega on kerge rikkuda oma küttesüsteemid, aga halvemal juhul võib see lõppeda tulekahjuga.
          <br />
          Hea küttepuu on kuiv, ideaalis lausa kaks aastat kuivanud. Puud peaksid olema toatemperatuuril ehk enne kütmist olema 24 tundi toas seisnud. Kindlasti ei tohi puid kuivatada või soojendada näiteks pliidi peal..
          <br />
          Samuti tuleb puid hoiustada küttekoldest ohutus kauguses. Ahjust või pliidi alt tuhka kogudes tuleb meeles pidada, et seda tuleks teha jahtunud tuha puhul. Kasutada tuleb mittepõlevat kaanega ämbrit ning hoida seda põlevmaterjalist eemal. Nii kamina kui ka ahju ees peab olema kindlasti korralik tulekindlast materjalist kaitse.
        </p>
      </div>

    </section>

  </Layout>
);

export default KKKPage;
